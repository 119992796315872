<template>
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="70%" top="5vh" @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form ref="formValidate" class="form" label-width="100px" :model="developer" :rules="ruleValidate">
        <el-tabs v-model="currentTab" type="card">
          <el-tab-pane :label="$t('developer.information')" name="first">
            <el-form-item :label="$t('developer.appKey')" prop="appKey">
              <el-input v-model="developer.appKey" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('developer.appSecret')" prop="appSecret">
              <el-input v-model="developer.appSecret" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('developer.name')" prop="name">
              <el-input v-model="developer.name" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$l('developer.callbackUrl', 'BaseUrl')" prop="callbackUrl">
              <el-input v-model="developer.callbackUrl" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('developer.address')" prop="address">
              <el-input v-model="developer.address" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('developer.contact')" prop="contact">
              <el-input v-model="developer.contact" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('developer.mobile')" prop="mobile">
              <el-input v-model="developer.mobile" :placeholder="$t('common.pleaseEnter')" :maxlength="11"></el-input>
            </el-form-item>
            <el-form-item :label="$t('developer.status')" prop="status">
              <el-radio-group v-model="developer.status">
                <el-radio class="radio" :label="0">{{ $t("developer.close") }}</el-radio>
                <el-radio class="radio" :label="1">{{ $t("developer.activate") }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane :label="$t('developer.Subscription')" name="second">
            <el-table :data="developerCallbackList">
              <el-table-column prop="type" :label="$t('developer.type')">
                <template slot-scope="scope">
                  <span v-if="scope.row.type === 'alarm'">
                    {{ $t("developer.baseAlarmUrl") }}
                  </span>
                  <span v-else-if="scope.row.type === 'alarmStatus'">
                    {{ $t("developer.baseAlarmStatusUrl") }}
                  </span>
                  <span v-else-if="scope.row.type === 'fault'">
                    {{ $t("developer.baseFaultUrl") }}
                  </span>
                  <span v-else-if="scope.row.type === 'event'">
                    {{ $t("developer.baseEventUrl") }}
                  </span>
                  <span v-else>
                    {{ $t("developer.baseTerminalUrl") }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="url" :label="$t('developer.baseUrl')">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.url"></el-input>
                </template>
              </el-table-column>
            </el-table>

            <!--            <el-form-item :label="$t('developer.baseUrl')" prop="baseUrl" labelWidth="120px">-->
            <!--              <el-input :placeholder="$t('common.pleaseEnter')" v-model="developer.baseUrl"></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item>-->
            <!--              <el-checkbox-group v-model="developer.subscriptionServiceList">-->
            <!--                <el-checkbox :label="0">{{$t('developer.subscriptionServiceList.alarm')}}</el-checkbox>-->
            <!--                <el-checkbox :label="5">{{$t('developer.subscriptionServiceList.remove')}}</el-checkbox>-->
            <!--                <el-checkbox :label="6">{{$t('developer.subscriptionServiceList.repeal')}}</el-checkbox>-->
            <!--                <el-checkbox :label="10">{{$t('developer.subscriptionServiceList.affair')}}</el-checkbox>-->
            <!--                <el-checkbox :label="15">{{$t('developer.subscriptionServiceList.fault')}}</el-checkbox>-->
            <!--              </el-checkbox-group>-->
            <!--            </el-form-item>-->
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">
        {{ $t("common.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
const moduleName = "developers";
export default {
  data() {
    return {
      dialogVisible: false,
      contentLoading: false,
      submitLoading: false,
      saveDisabled: false,
      currentTab: "first",
      developerCallbackList: [
        { id: 0, type: "alarm", url: "" },
        { id: 0, type: "alarmStatus", url: "" },
        { id: 0, type: "fault", url: "" },
        { id: 0, type: "event", url: "" },
        { id: 0, type: "terminal", url: "" },
      ],
      developer: {
        id: 0,
        name: "",
        address: "",
        contact: "",
        mobile: "",
        appKey: "",
        appSecret: "",
        status: 0,
        callbackUrl: "",
        subscriptionService: "",
        subscriptionServiceList: [],
        developerCallbacks: [],
      },
      ruleValidate: {
        appKey: [{ required: true, min: 1, message: this.$t("developer.tip.no"), trigger: "blur" }],
        appSecret: [
          {
            required: true,
            min: 8,
            max: 8,
            pattern: /^[A-Za-z0-9]{8}$/,
            message: this.$t("developer.tip.appSecret"),
            trigger: "blur",
          },
        ],
        name: [{ required: true, min: 1, message: this.$t("developer.tip.name"), trigger: "blur" }],
        mobile: [{ pattern: /^1[0-9]{10}$/, message: this.$t("developer.tip.mobile"), trigger: "blur" }],
      },
    };
  },
  computed: {
    title() {
      return (this.developer.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("developer.developer");
    },
  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.developer.id = id;
      if (id > 0) {
        this.getData();
      }
    },
    getData() {
      this.contentLoading = true;
      this.saveDisabled = true;
      this.$api
        .getById(moduleName, this.developer.id)
        .then((res) => {
          this.developer = res.data;
          for (let item of this.developer.developerCallbacks) {
            if (item.type === "alarm") {
              this.developerCallbackList[0].url = item.url;
              this.developerCallbackList[0].id = item.id;
            } else if (item.type === "alarmStatus") {
              this.developerCallbackList[1].url = item.url;
              this.developerCallbackList[1].id = item.id;
            } else if (item.type === "fault") {
              this.developerCallbackList[2].url = item.url;
              this.developerCallbackList[2].id = item.id;
            } else if (item.type === "event") {
              this.developerCallbackList[3].url = item.url;
              this.developerCallbackList[3].id = item.id;
            } else {
              this.developerCallbackList[4].url = item.url;
              this.developerCallbackList[4].id = item.id;
            }
          }
          this.contentLoading = false;
          this.saveDisabled = false;
        })
        .catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
    },
    onDialogClose() {
      this.$refs.formValidate.resetFields();
      this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
    },
    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.developer.developerCallbacks = this.developerCallbackList;
          this.$api
            .save(moduleName, this.developer)
            .then((res) => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.developer.id);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            })
            .catch((error) => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
        } else {
          this.$message.error(this.$t("common.tip.submitError") + "!");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
